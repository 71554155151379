export default class buttons {
    
    constructor(template)   {this.template = template}

    pressed(ifPressButton='', idButton='', currentClass='')   {

        if(ifPressButton.trim().length===0)  return
        if(idButton.trim().length===0)  return
        if(currentClass.trim().length===0)  return
        
        let element = document.getElementById(idButton)
        element.classList.add(currentClass + '-click')
        setTimeout(() => {
            element.classList.remove(currentClass + '-click')
            switch(ifPressButton)   {
                case 'modal-close': {this.template.viewSendMessages=false; break;}
                case 'modal-alert-close': {this.template.viewAlertWindow=false; break;}
                case 'modal-clear-form':    {
                    this.template.textAreaMessage = ''
                    this.template.textMailAddress = ''
                    this.template.allowedClear = this.template.allowedButtons(1)
                    this.template.allowedSend = this.template.allowedButtons(2)                                        
                    break
                }
                case 'clearMessage':     {
                    this.template.clearData()
                    break
                }
                case 'modal-send-message':    {
                    this.template.doSendMessage()
                    break
                }
                case 'call-gdpr':    {
                    this.exampleModal = false
                    window.location.href='https://gdpr-slovensko.sk';
                    break
                }                
            }
        }, 500);        
    }    
}