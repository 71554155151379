<template>
    <div>
        <div class="mobile-area  container-fluid " :class="{'mobile-area-open':isOpenMenu}"  style="z-index:1000;" >
            <div @click="openMenu" class="mobile-area-space">

                <img  v-if="projectNameIs==='-818'"     :src="httpsPath + 'logos/logo-in.png'" alt="Spoločnosť 818" class="mobile-area-logo rounded-circle cursor" style="" />

                <img  v-if="projectNameIs==='-abnet'"   :src="httpsPath + 'logos/logoLS.jpg'" alt="Spoločnosť 818" class="mobile-area-logo-abnet  cursor" style="" />

            </div>
            <div class="mobile-area-work-space">
                <div class="mobile-area-work-space-in">
                    <div  :class="{'mobile-area-close-space':!isOpenMenu, 'mobile-area-view-space':isOpenMenu,}">
                        <div @click="closeMenu('info')"  :class="{'mobile-area-inline-off':!isMenu1, 'mobile-area-inline':isMenu1,'mobile-area-item-on':currentUrl==='info'}" class="mobile-area-item">
                            <li>{{$t('menu.1'+projectNameIs)}}</li>
                        </div>
                        <div  @click="closeMenu('favorite')" :class="{'mobile-area-inline-off':!isMenu2, 'mobile-area-inline':isMenu2,'mobile-area-item-on':currentUrl==='favorite'}" class="mobile-area-item">
                            <li>{{$t('menu.2')}}</li>
                        </div>
                        <div  @click="closeMenu('search')" :class="{'mobile-area-inline-off':!isMenu3, 'mobile-area-inline':isMenu3,'mobile-area-item-on':currentUrl==='search'}" class="mobile-area-item">
                            <li>{{$t('menu.3')}}</li>
                        </div>
                        <div  @click="closeMenu('details')" :class="{'mobile-area-inline-off':!isMenu4, 'mobile-area-inline':isMenu4,'mobile-area-item-on':currentUrl==='details'}" class="mobile-area-item">
                            <li v-if="projectNameIs==='-818'">{{$t('menu.4')}}</li>
                            <li v-if="projectNameIs==='-abnet'">{{$t('menu.5')}}</li>
                        </div>        
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
    import System from "@/assets/modules/System";


    export default ({
        name: 'MenuTopMobile',  
        data() {
            return {
                projectNameIs: new System().projectName(),

                currentUrl: window.location.href.split('/')[3],
                isOpenMenu: false,
                isMenu1: false,
                isMenu2: false,
                isMenu3: false,
                isMenu4: false,

                httpsPath: localStorage.httpsPath,
            }
        },
        methods: {
            closeMenu(path) {
                this.isOpenMenu = false
                this.currentUrl = path
                this.$router.push('/' + path)
            },

            openMenu() {
                this.isOpenMenu = !this.isOpenMenu
                if(this.isOpenMenu) {
                    
                    this.isMenu1 = false
                    this.isMenu2 = false 
                    this.isMenu3 = false 
                    this.isMenu4 = false 

                    setTimeout(() => {
                       this.isMenu1 = true 
                    }, 300);
                    setTimeout(() => {
                       this.isMenu2 = true 
                    }, 600);                    
                    setTimeout(() => {
                       this.isMenu3 = true 
                    }, 900);       
                    setTimeout(() => {
                       this.isMenu4 = true 
                    }, 1200);                                                                         
                } else {
                       this.isMenu1 = false
                       this.isMenu2 = false 
                       this.isMenu3 = false 
                       this.isMenu4 = false                     
                }
            }
        },
        computed: {
            getStoreValue() {
                return this.$store.state.reloadMobileMenu
            }
        },
        watch: {
            getStoreValue(newValue, oldValue) {   //
                if(newValue != oldValue && newValue.trim().length>0) {
                    this.currentUrl = newValue
                    this.$store.commit('changeMobileMenuNew', '')
                    this.$forceUpdate();
                }
            }
        },        
    })
</script>

<style lang="scss" scoped>




.mobile-area    {
    padding:$menu-mobile-area-padding;
    background-color:$menu-mobile-area-bg; 
    font-family:$menu-mobile-area-family;
    height:$menu-mobile-area-height;
    width:$menu-mobile-area-width;
    transition-duration: $menu-mobile-area-trans;
    -webkit-box-shadow: 6px 14px 47px -8px #000;
    box-shadow: 6px 14px 47px -8px #000;
    transition-property: height;
    clear: both;
    &-open  {
        padding:$menu-mobile-area-open-padding;
        background-color: $menu-mobile-area-open-bg;
        width:$menu-mobile-area-open-width;
        height:$menu-mobile-area-open-height;
        transition-duration: $menu-mobile-area-open-trans;
        transition-property: height;
        clear: both;
    }
    &-space {
        width: $menu-mobile-area-space-width;
        text-align:$menu-mobile-area-space-align;
        clear:both;
        float:left;
    }
    &-logo  {
        &-abnet {
            width:$menu-mobile-area-logo-abnet-width;
            height:$menu-mobile-area-logo-abnet-height;
            border-radius: $menu-mobile-area-logo-abnet-radius;
            margin: $menu-mobile-area-logo-abnet-margin;
        }
        width:$menu-mobile-area-logo-width;
        height:$menu-mobile-area-logo-height;
        margin: $menu-mobile-area-logo-margin;
    }
    &-work-space {
        width:$menu-mobile-area-work-space-width;
        padding:$menu-mobile-area-work-space-space;
        float: left;
        &-in    {
            margin:$menu-mobile-area-work-space-in-margin;
            width:$menu-mobile-area-work-space-in-width;
        }
    }
    &-view-space    { display:inline;}
    &-close-space   { display:none;}
    &-item  {
        margin-top:$menu-mobile-area-item-margin;
        font-size:$menu-mobile-area-item-size;
        font-weight:$menu-mobile-area-item-weight;
        color:$menu-mobile-area-item-col;
        clear:both;
        cursor:pointer;        
        &-on   {
            margin-top:$menu-mobile-area-item-on-margin;
            font-size:$menu-mobile-area-item-on-size;
            font-weight:$menu-mobile-area-item-on-weight;
            color:$menu-mobile-area-item-on-col; 
            clear:both;
        }
    }
    &-inline { 
        display: block; 
        &-off { 
            display: none;
        } 
    }
}

</style>