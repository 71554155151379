<template>
<!--<div>-->

    <div id="id-company-top"  class=" company-top">
        
        <div id="id-items-0"    class="items-0">
                <a v-if="viewFacebook" href="https://www.facebook.com/rastislav.rehak1/" target="_new">
                    <i class="company-top-icon fb fa-brands fa-facebook"></i>
                </a>    
                <a v-if="viewInstagram" href="https://www.instagram.com" target="_new">
                    <i class="company-top-icon inst fa-brands fa-instagram"></i>
                </a>
                <a v-if="viewTwitter"    href="https://www.twitter.com" target="_new">
                    <i class="company-top-icon twit fa-brands fa-twitter"></i>
                </a>    
                <a v-if="vievLinkedIn" href="https://www.linkedin.com/in/rastislav-rehák-a14b191b6/?msgControlName=reply_to_sender&msgConversationId=2-ZDc4YzhmNTktNDdlNS00YmMzLWE2MGUtYzQyODNkZWFmMWNkXzAxMw%3D%3D&msgOverlay=true" target="_new">
                    <i class="company-top-icon in fa-brands fa-linkedin"></i>
                </a>
        </div>
        <div id="id-items-1"    class="items-1">
            &nbsp;{{$t('topLine.1'+projectNameIs)}}&nbsp;
        </div>
        <div id="id-items-2"    class="items-2">
            <i class="fas fa-phone-volume"></i>
            &nbsp;{{$t('topLine.2'+projectNameIs)}}&nbsp;
        </div>
        <div id="id-items-3"    class="items-3">
            <msg    :globalMail="1" 
                    :maskClasses="'ext-top-company'" />
        </div> 
        <div id="id-items-4"    class="items-4">  
            <img v-if="$i18n.locale=='en'" @click="changeLanguage" :src="httpsPath + 'flags-language/sk.jpg'" alt="" class="currentLang" />
            <img v-if="$i18n.locale=='sk'" @click="changeLanguage" :src="httpsPath + 'flags-language/en.jpg'" alt="" class="currentLang" />
        </div> 
    </div>
<!--</div>--->

</template>

<script>
    import msg from "@/components/Modals/MessageToMakler.vue"
    import url from "@/assets/modules/URLMethods"
    import System from "@/assets/modules/System";


    export default {
        name: 'CompanyTop',  
        components: {
            msg
        },
        computed: {},
        data() {
            return {
                System: new System(),
                URL: new url(),

                projectNameIs: new System().projectName(),

                viewFacebook:true,
                viewInstagram:true,
                vievLinkedIn:true,
                viewTwitter:true,

                httpsPath:localStorage.httpsPath,
            };      
        },  
        created() {
            window.addEventListener('scroll', this.scrollControl);
        },

        methods: {

            scrollControl() {
                if(screen.width>992) {
                    let ora = 50
                    if(document.documentElement.scrollTop>ora)    this.topHide(true)
                    if(document.documentElement.scrollTop<=ora)    this.topHide(false)
                }
            },
            callWebSite(webSite) {
                //console.log(webSite)
                window.location.href = webSite
            },
            changeLanguage() {
                if(localStorage.lang == 'sk' )   {
                    this.$i18n.locale = 'en'
                        
                }
                if(localStorage.lang == 'en' )   {
                    this.$i18n.locale = 'sk'
                }
                this.URL.setLangInURL(this.$i18n.locale)
                localStorage.lang = this.$i18n.locale
                let rerender = this.$store.state.rerender
                ++rerender
                this.$store.commit('rerenderSet', rerender)    
                
            },
            topHide(hide=true) {
                let element = document.getElementById("id-company-top")

                let items_0 = document.getElementById("id-items-0")
                let items_1 = document.getElementById("id-items-1")
                let items_2 = document.getElementById("id-items-2")
                let items_3 = document.getElementById("id-items-3")
                let items_4 = document.getElementById("id-items-4")

                items_0.classList.remove('items-0')
                items_1.classList.remove('items-1')
                items_2.classList.remove('items-2')
                items_3.classList.remove('items-3')
                items_4.classList.remove('items-4')

                items_0.classList.remove('items-hide-0')
                items_1.classList.remove('items-hide-1')
                items_2.classList.remove('items-hide-2')
                items_3.classList.remove('items-hide-3')
                items_4.classList.remove('items-hide-4')

                element.classList.remove('company-top-animation-view')  
                element.classList.remove('company-top-animation-hide')  

                if(hide) {
                    items_0.classList.add('items-hide-0')
                    items_1.classList.add('items-hide-1')
                    items_2.classList.add('items-hide-2')
                    items_3.classList.add('items-hide-3')
                    items_4.classList.add('items-hide-4')                               
                    element.classList.add('company-top-animation-hide')

                } else {
                    items_0.classList.add('items-0')
                    items_1.classList.add('items-1')
                    items_2.classList.add('items-2')
                    items_3.classList.add('items-3')
                    items_4.classList.add('items-4')                   
                    element.classList.add('company-top-animation-view')
                }
            }
        },

        mounted () {
            /*
            setTimeout(() => {
                this.topViewAndHide()
            }, 2000);
            */
        },

    }
</script>

<style lang="scss" scoped>

.fb,.inst, .twit, .in { padding:2px 0 2px 0;}

.currentLang { width:$top-lang-width; height:auto; cursor: pointer;}

.company-top    {
    background-color: $top-bg;
    color:$top-col;
    height:$top-height;
    font-family:$top-family;
    font-size:$top-size;
    padding:$top-padding;
    &-icon {
        width:$top-icons-width;
        height:auto;
        font-size:$top-icons;
        color:$top-col;
        margin: $top-icons-margin;
        cursor:pointer;            
    }
}

.company-top-animation-hide    {
    //border:solid 2px green;
    height:0px;
    padding:0px;
    animation-name: top-hide;
    animation-duration: 0.5s;
}


@keyframes top-hide {
    from {height:$top-height;}
    to {height:0px;}
}


.company-top-animation-view    {
    //border:solid 2px blue;
    height:$top-height;
    padding:$top-padding;
    animation-name: top-view;
    animation-duration: 0.3s;
}

@keyframes top-view {
    from {height:0px;}
    to {height:$top-height;}
}


.items    {
    margin:0px;
    border:0px;
    &-0, &-1, &-2, &-3, &-4  {
        float:left;
        text-align:center;
        padding-top:2px;
        display: inline;
    }
    &-0 { clear:both; width:30%;}    
    &-1 { width:20%;}
    &-2 { width:20%;}
    &-3 { width:20%;}
    &-4 { width:10%;}
}

.items-hide    {
    margin:0px;
    border:0px;
    &-0, &-1, &-2, &-3, &-4  {
        float:left;
        text-align:center;
        padding-top:2px;
        display:none;
    }
    &-0 { clear:both; width:30%;}    
    &-1 { width:20%;}
    &-2 { width:20%;}
    &-3 { width:20%;}
    &-4 { width:10%;}
}

@media screen and (max-width: 1300px) {
    .items    {
        &-0 { width:20%; font-size:0.7em; padding-top:5px;}
        &-1 { width:20%;}
        &-2 { width:25%;}
        &-3 { width:25%;}
        &-4 { width:10%;}
    }    
}


@media screen and (max-width: 1050px) {
    .items    {
        &-0 { width:20%;}
        &-1 { display:none;}
        &-2 { width:35%;}
        &-3 { width:35%;}
    }
}

@media screen and (max-width: 770px) {
    .items    {
        &-0 { display:none;}
        &-2 { width:45%;}
        &-3 { width:45%;}
    }
}

@media screen and (max-width: 600px) {
    .items    {
        &-2 { font-size:0.8em; padding-top:5px;}
        &-3 { font-size:0.8em; padding-top:5px;}
    }
}

@media screen and (max-width: 470px) {
.items    {
        &-2 { display:none;}
        &-3 { width:80%;}
        &-4 { width:20%;}
    }    
}

</style>