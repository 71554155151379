<template>
    <div>
        <div    :class="maskClasses+'-global' " 
                aria-controls="sendMessages" 
                @click="openModal" 
                v-if="globalMail==0 || typeof globalMail=='undefined'"  >

            <div :class="maskClasses">
                {{$t('modalSendMessage.1')}}
                &nbsp;&nbsp;&nbsp;
                <i :class="maskClasses+'-icon fa-solid fa-pencil'"></i>
            </div>
        </div>

        
        <div    aria-controls="sendMessages" 
                @click="openModal" 
                v-if="globalMail==1">

            <!---ext-cool-link-concat-us--->
            <div :class="maskClasses">
                <i class="fas fa-envelope-square"></i>
                &nbsp;{{$t('topLine.3'+projectNameIs)}}&nbsp;
            </div>

        </div>        

        <!---
        <div    aria-controls="sendMessages" 
                @click="openModal" 
                :class="maskClasses+'-global'"
                v-if="globalMail==2">

            <div :class="maskClasses+'-global-link'">
                <i class="fa-solid fa-envelope-circle-check"></i>
                &nbsp;
                {{$t('modalSendMessage.1')}}
            </div>
        </div>   
        -->

        <!------------>
        <div    aria-controls="sendMessages" 
                @click="openModal" 
                v-if="globalMail==3">

                <div class="pack-more-details">
                    <span class="more-details-in"> <!---cool-link-std-->
                        {{$t('why-818.2')}}.
                    </span>
                </div>
        </div>    
        <!------------>

        <div    aria-controls="sendMessages" 
                @click="openModal" 
                v-if="globalMail==4">

                <div class="general-line-1-5 mb-5">
                    <span class="cool-link-std global-4">
                        {{$t('modalSendMessage.25')}}.
                    </span>
                </div>
        </div>      

        <MDBModal   id="sendMessages"
                    tabindex="-1"
                    labelledby="sendMessagesLabel"
                    v-model="viewSendMessages"  
                    class="modal"
        >            
            <div class="modal-wind">
                <MDBModalHeader class="modal-wind-title">
                    <MDBModalTitle id="sendMessagesLabel" >
                        <div v-if="globalMail===0 || globalMail===2 || typeof globalMail==='undefined'">
                            {{$t('modalSendMessage.1')}}
                        </div>
                        <div v-if="globalMail===1 || globalMail===3 || globalMail===4 ">
                            {{$t('modalSendMessage.21')}}
                        </div>
                        
                    </MDBModalTitle>
                </MDBModalHeader>
                
                <MDBModalBody class="modal-wind-body">

                    <div v-if="formix==2" class="">
                        <div class="modal-wind-error-send-icon">
                            <i class="fa-solid fa-file-circle-exclamation"></i>
                        </div>
                        <div class="modal-wind-error-send">
                            <div class="modal-wind-error-send-message">
                                {{$t('modalSendMessage.12')}}
                            </div>
                            <p>{{$t('modalSendMessage.13')}}</p>
                        </div>                    
                    </div>

                    <div v-if="formix==1">
                        <div class="modal-wind-success-send-icon">
                            <i class="fa-solid fa-envelope-circle-check"></i>
                        </div>
                        <div class="modal-wind-success-send">
                            <div class="modal-wind-success-send-message">
                                {{$t('modalSendMessage.9'+projectNameIs)}}
                            </div>                        
                            <p>{{$t('modalSendMessage.10'+projectNameIs)}}</p>
                        </div>
                    </div>
                    <div v-if="formix==0">
                        <div v-if="Object.keys(this.oMakler).length>0" class="modal-wind-to-makler ">
                            <div class="modal-wind-to-makler-left d-flex justify-content-center">
                                <img :src="httpsPath + 'brokers/'+oMakler.photo" class="modal-wind-makler-space-photo"  alt="" />
                            </div>

                            <div class="modal-wind-to-makler-right ">

                                <table  width="100%">
                                    <tr>
                                        <td class="modal-wind-to-makler">
                                            {{$t(getPerson())}}:
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="modal-wind-to-makler-info">
                                            {{oMakler.titleBefore + ' ' + oMakler.name + ' ' + oMakler.surName + ' ' + oMakler.titleAfter}}
                                        </td>                                    
                                    </tr>
                                    <tr>
                                        <td class="modal-wind-to-makler">
                                            {{$t('modalSendMessage.7')}}:
                                        </td>
                                    </tr>                                    
                                    <tr>
                                        <td class="modal-wind-to-makler-info">
                                            &nbsp;{{oMakler.phone}}
                                        </td>                                    
                                    </tr>
                                </table>
                                
                            </div>
                        </div>

                        <div v-if="globalMail==0 || globalMail==2 || typeof globalMail=='undefined'">

                            <div class="modal-wind-specify-note">
                                {{$t('modalSendMessage.8')}}:
                            </div>                    
                            <div class="modal-view-estate">
                                <img :src="httpsPath + 'estates-small/'+oOffer.photo" class="modal-estate-image " alt=""  />
                            </div>
                            <div class="modal-wind-specify">
                                {{typeEstate + ', ' + locality}}
                            </div>
                                                        
                            <div class="modal-wind-specify">
                                {{$t(getTypeOffer(oOffer.forSale))}}
                            </div>          

                            <div class="modal-wind-specify-detail">
                                
                                <span v-if="$i18n.locale=='sk' && isTitle_sk()">{{oOffer.title_sk}}</span>
                                <span v-if="$i18n.locale=='en' && isTitle_en()">{{oOffer.title_en}}</span>

                            </div>

                            <div v-if="aDescription()">

                                <!---v-if="Object.keys(aDescription_sk).length>1 || aDescription_sk[0].trim().length>0"-->
                                <div class="modal-wind-open-full" @click="setFullDescription" v-if="descriptionLength()">   
                                    <span v-if="!viewFullDescription">
                                        {{$t('modalSendMessage.19')}}&nbsp;
                                        <i class="fa-solid fa-down-long"></i>                                    
                                    </span>
                                    <span v-if="viewFullDescription">
                                        {{$t('modalSendMessage.20')}}&nbsp;
                                        <i class="fa-solid fa-up-long"></i>                                    
                                    </span>
                                </div>
                                
                                <div    class="modal-wind-open-full-description" 
                                        v-if="viewFullDescription">   

                                    <div    v-if="$i18n.locale=='sk'">
                                        <p v-for="(description, i) in aDescription_sk" :key="i">{{description}}</p>
                                    </div>

                                    <div    v-if="$i18n.locale=='en'">
                                        <p v-for="(description, i) in aDescription_en" :key="i">{{description}}</p>
                                    </div>
                                </div>
                            </div>                  

                        </div>

                        <div    v-if="globalMail===1 || globalMail===3 || globalMail===4"
                                class="modal-wind-add-info">
                            {{$t('modalSendMessage.22')}}
                        </div>

                        <input      type="text" 
                                    v-model="textMailAddress" 
                                    class="modal-text-input"
                                    :class="{'modal-err-input':textMailAddress.trim().length>0 && !regExpMail(textMailAddress)}" 
                                    @keyup="writeToForm()" 
                                    :placeholder="$t('modalSendMessage.5')" />

                        <textarea   v-model="textAreaMessage" 
                                    @keyup="writeToForm()" 
                                    class="modal-text-area" 
                                    :class="{'modal-err-area':textAreaMessage.trim().length>0 && textAreaMessage.trim().length<10}" 
                                    name="" id="contentMessage" 
                                    :placeholder="$t(textAreaPlaceHolder())" />
                    </div>

                </MDBModalBody>

                <MDBModalFooter class="modal-footer d-flex justify-content-center">


                    <input id="modal-button-close" class="modal-btn-act" type="button"   @click="closeModal()" :value="$t('modalSendMessage.11')" />

                    <div v-if="formix==0">
                        
                        <input id="modal-button-clear-form" class="modal-btn-warn" type="button" v-if="allowedClear" style="" @click="clearForm()"  :value="$t('modalSendMessage.2')" />
                        <input class="modal-btn-warn-disabled"    type="button" v-if="!allowedClear" disaled :value="$t('modalSendMessage.2')" />
                        <!---------------------------------------------->
                        <input id="modal-button-send" class="modal-btn-act" type="button" v-if="allowedSend"  @click="sendMessage()"   :value="$t('modalSendMessage.3')" />
                        <input class="modal-btn-act-disabled" type="button" v-if="!allowedSend" disaled :value="$t('modalSendMessage.3')" />
                        <!---style="cursor: pointer;" --->
                    </div>
                    
                </MDBModalFooter>
            </div>
        </MDBModal>
    </div>
</template>

<script>

import axios from 'axios'

//import effects from "@/assets/modules/Effects"
import buttons from "@/assets/modules/Buttons"

import System from "@/assets/modules/System";

import {
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        //MDBBtn,
    } from 'mdb-vue-ui-kit';

    export default {
        components: {
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter,
            //MDBBtn,
        },      
        data() {
            return {
                projectNameIs: new System().projectName(),

                buttons: new buttons(this),

                //Effects: new effects(),
                formix:0,
                allowedSend:false,
                allowedClear:false,
                viewSendMessages:false,
                viewFullDescription:false,
                textAreaMessage:'',
                textMailAddress:'',         //rasto@abnet.sk
                oMakler:[],
                aDescription_sk:[],
                aDescription_en:[],

                httpsPath:localStorage.httpsPath,
            }
        },  
        props: {
            oOffer: {
                type: Object,
            },

            typeEstate: {
                type: String,
            },
            locality: {
                type: String,
            },
            globalMail: {
                type:Number,
            },
            maskClasses:  {
                type: String,
            },
        },
        methods: {
            descriptionLength() {

                let aDescription 
                if(this.$i18n.locale=='sk')   aDescription = this.aDescription_sk
                if(this.$i18n.locale=='en')   aDescription = this.aDescription_en

                let returnValue = false

                if(Object.keys(aDescription).length>1) {
                    returnValue = aDescription[0].trim().length > 0
                }

                return returnValue
            },
            isTitle_sk()    {
                return  typeof this.oOffer.title_sk ==='string'
            },
            isTitle_en()    {
                return  typeof this.oOffer.title_en ==='string'
            },
            aDescription() {
                //console.log(typeof this.aDescription_sk)   
                return typeof this.aDescription_sk === 'object'
            },
            setFullDescription() {
                this.viewFullDescription = !this.viewFullDescription
            },
            getTypeOffer(forSale)  {
                let typeOffer

                switch(forSale) {
                    case 0: {
                        typeOffer = 'estate.2'
                        break
                    }
                    case 1: {
                        typeOffer = 'estate.1'
                        break
                    }
                    case 2: {
                        typeOffer = 'estate.9'
                        break
                    }
                    case 3: {
                        typeOffer = 'estate.11'
                        break
                    }                    
                }
                return typeOffer
            },
            sendMessage() {
                this.buttons.pressed(   'modal-send-message', 
                                        'modal-button-send', 
                                        'modal-btn-act')                
                this.allowedSend = false
            },
            doSendMessage() {

                setTimeout(() => {

                    let aLines = this.textAreaMessage.split('\n')
                    let offerID = 1

                    let correctMessage = ''
                    if(typeof this.oOffer != 'undefined')   {
                        offerID = this.oOffer.id
                        correctMessage = 'Správa sa týka ponuky: ' + localStorage.http + localStorage.webURL + '/favorite?id=' + offerID + '~~'
                    }

                    let comma=''
                    for(let i=0;i<aLines.length;++i) {
                        correctMessage += comma + aLines[i]
                        comma = '~'
                    }

                    let mailTo = localStorage.appMail
                    //console.log(localStorage.appMail)
                    if(typeof this.oMakler.mail != 'undefined') {
                        if(this.regExpMail(this.oMakler.mail)) {
                            mailTo = this.oMakler.mail
                        }
                    }

                    //console.log(localStorage.REST_API_URL + '&command=send'+'&msg='+correctMessage+'&from='+this.textMailAddress+'&to='+mailTo)
                    axios.get(  localStorage.REST_API_URL + 
                                '&command=send'+
                                '&msg='+correctMessage+
                                '&from='+this.textMailAddress+
                                '&to='+mailTo)
                    .then(system => {

                        if(typeof system.data[0].error === 'string') {
                            console.error(system.data[0].error)
                            this.formix = 2
                        } else 
                            this.formix = 1                            

                        this.outOfNull(system)
                        
                        setTimeout(() => {
                                this.viewSendMessages=false
                                this.formix = 0
                                this.textAreaMessage=''
                                this.textMailAddress=''}, 3000);
                    
                    }).catch(function(error) {

                            this.formix = 2
                            console.log('CATCH :: '+error.message)
                            setTimeout(() => {
                                this.viewSendMessages=false
                                this.formix = 0}, 3000);
                    })
                }, 200);
            },

            outOfNull() {},

            clearForm()  {

                this.buttons.pressed(   'modal-clear-form', 
                                        'modal-button-clear-form', 
                                        'modal-btn-warn')
            },
            writeToForm() {
                this.allowedClear = this.allowedButtons(1)
                this.allowedSend = this.allowedButtons(2)
            },
            openModal() {
                this.viewSendMessages=true
            },
            closeModal() {
                this.buttons.pressed(   'modal-close',
                                        'modal-button-close',
                                        'modal-btn-act'   )
            },
            allowedButtons(variant) {
                let mailLength = this.textMailAddress.trim().length
                let messageLength = this.textAreaMessage.trim().length

                if(variant==1) return mailLength > 0  ||  messageLength > 0
                if(variant==2) return this.regExpMail(this.textMailAddress)  &&  messageLength > 9
            },
            regExpMail(mail) {
                let regExp     =   /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return regExp.test(mail)
            }, 
            textAreaPlaceHolder() {
                if(this.globalMail == 0) return 'modalSendMessage.4'
                if(this.globalMail == 2) return 'modalSendMessage.4'
                if(this.globalMail == 1) return 'modalSendMessage.14'
                if(this.globalMail == 3) return 'modalSendMessage.4'
                if(this.globalMail == 4) return 'modalSendMessage.23'
            },
            getPerson() {
                if(this.globalMail == 0) return 'modalSendMessage.6'
                if(this.globalMail == 2) return 'modalSendMessage.6'
                if(this.globalMail == 1) return 'modalSendMessage.16'                
                if(this.globalMail == 3) return 'modalSendMessage.6'
                if(this.globalMail == 4) return 'modalSendMessage.24'
            }
        },
        mounted () {

            setTimeout(() => {
                if(typeof this.oOffer != 'undefined') {
                    this.aDescription_sk = this.oOffer.description_sk.split('~')
                    this.aDescription_en = this.oOffer.description_en.split('~')

                    this.formix = 0
                    let oTemporary = this.$store.state.oMaklers
                    let getMakler
                    if(this.globalMail==0 || this.globalMail==2) {
                        getMakler = this.oOffer.makler
                    }
                    else    
                        getMakler = 1
                    
                    for(let key in oTemporary) {
                        if(oTemporary[key].id===getMakler) {
                            this.oMakler = oTemporary[key]
                            break
                        }
                    }                
                }
            }, 2000);
        },
    }
</script>

<style lang="scss" scoped>






/*
.ext-send-mail-from-desc {
    background-color: $bg-light;
    color:$col;
    font-size: 0.8em;
    font-weight: 700;
    text-align: center;
    height:45px;
    cursor: pointer;
}


.ext-cool-link-mail-offer {
    width:250px;
    margin: 0 auto 0 auto;
    margin-left:auto;
    margin-right:auto;
    display:  block;
    text-decoration: none !important;
    font-family:$font-estate;
    //border:solid 1px red;
}


.ext-cool-link-mail-offer::after {
    content: '';
    display: block;
    width: 0%;
    height: 3px;
    background: transparent;
    border:0px;
    transition: width .3s;
    -webkit-transition: color 1s;
    transition: color 1s; 
    color:$col;
}


.ext-cool-link-mail-offer:hover::after {
    width: 100%;
    transition: width .3s;
    background:$col;
    border:0px;
    color:$col;
    margin: 3px 0 0 0;
}
*/

.global-4 {
    clear:both;
    margin: 70px 0 1070px 0;
    border:solid 0px red;
}



</style>