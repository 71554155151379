export default {
  "chat": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a question"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are very sorry,"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["but all our brokers are"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is currently"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ask"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case we are currently"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and you need to reach out to us, there are several other options."])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can contact us by phone at"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also contact us by email"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatively, introduce yourself to us, for example, with your email or another name, and "])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send us a message"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name or email (max 5 characters)"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please introduce yourself"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can send us a message"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send us a message"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't forget to leave a contact for you"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been successfully delivered to us, and we thank you for sending it"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will respond to your message as soon as we are "])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the event that communication cannot be established using"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we will contact you by email, phone or depending on whether and what contact you sent us"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We know you by this name"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please message at least 10 characters"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open communication with this correspondent"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the correspondent with his communication"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondents"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the list"])}
  },
  "banners": {
    "banner-1": {
      "snapshot-1": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer you"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our services around real estate"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Mediation of purchase and sale"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Drawing up a reservation contract"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Drafting of the transfer agreement"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  We will provide an expert opinion"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  We will handle the deposit in the cadastre"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  We will arrange the energy transfer"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  We will also arrange a favorable mortgage for you"])}
      },
      "snapshot-2": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation contracts"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  For new housing, the company 818 can draw up a reservation contract for the selected property."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Since you will not have to personally arrange the necessary details regarding the reservation of the property, you will have more time and financial resources available to you."])}
      },
      "snapshot-3": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer contracts"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Company 818 will provide you with the drafting of not only purchase contracts, but also other types of contracts if the real estate transfer itself requires it."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  In this regard, the company can really provide you with absolute professionalism."])}
      },
      "snapshot-4": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert testimony"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  If an expert opinion is needed, you will also be provided with a competent and in all respects acceptable expert."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  As a bonus, the most favorable price on the market will be negotiated with the expert for the preparation of the expert opinion."])}
      },
      "snapshot-5": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit in the cadastre"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  The company 818 can also represent you in the final phase of the transfer of real estate at the cadastre, i.e. during the cadastral deposit procedure."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  If further communication with the cadastral department is necessary when depositing the property, all the necessary details will be thoroughly communicated with the relevant district office."])}
      },
      "snapshot-6": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energies and their transcription"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  As part of the transfer of real estate, the consumption of energy, such as electricity, water, gas, etc., is bound."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  During your move, the company will ensure energy transfer, while you can fully devote your time to the move."])}
      },
      "snapshot-7": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advantageous mortgage"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  We will arrange a mortgage exactly according to your needs, we have many years of experience with this."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•  Thus, we will ensure the most favorable and ideal financing, through which you can calmly acquire your new property."])}
      }
    }
  },
  "topLine": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["818 Ltd."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+421 948 818 818"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "1-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["818 Ltd."])},
    "2-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+421 948 818 818"])},
    "3-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "1-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land Solution Ltd."])},
    "2-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+421 904 478 738"])},
    "3-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email me"])}
  },
  "start-site": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find an offer"])},
    "title-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to find a property"])},
    "my-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["real estate office"])},
    "makler-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company 818,"])},
    "makler-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your real estate agency"])},
    "makler-name-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company 818,"])},
    "makler-type-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your real estate agency"])},
    "makler-name-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastislav Rehak, "])},
    "makler-type-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo application in VUE JS 3"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest offers"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["818 Ltd., production: Land Solution Ltd."])},
    "copy-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["818 Ltd., production: Land Solution Ltd."])},
    "copy-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastislav Rehak, production: Land Solution Ltd."])}
  },
  "menu": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we offer ?"])},
    "1-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we offer ?"])},
    "1-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I offer ?"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View offers"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in offers"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why 818 ?"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why the RK ?"])}
  },
  "base-info": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we offer you ?"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a real estate agency, 818 is one of the exclusive partners for Slovakia and the Czech Republic."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide a complete service for the sale, purchase and rental of even high-end residential or commercial real estate not only in attractive locations in Bratislava, but also in the whole of Western Slovakia, or anywhere in Slovakia, or even in the Czech Republic - "])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more info"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our company tries to meet all the demands and needs of our customers. With our services, you will only encounter a high-quality and professional approach, we will do our best to find the most suitable property for you with all the services you contact us with."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have more than ten years of quality experience in the real estate market as well as the services related to real estate business, and many satisfied customers, including you, if you decide to use our services."])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you decide to use"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our other services"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at the same time, you can get many other benefits from us."])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the end of this part of the web application, we can offer you, for example, the following properties:"])}
  },
  "favorite": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View offers"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate according to your criteria"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No property meets the specified criteria"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All offers"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All current offers"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our latest offers"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent offers"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent looking"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current offers"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested offer number does not exist!"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently no offer"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in the locality"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvoriť detail"])}
  },
  "estate": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FOR SALE"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RENT"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESIDENCE"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMERCIAL"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above standard"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFFER"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BUY"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novelty"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIRE WANTED"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to know more"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for the details on the offer"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entire text is displayed after confirmation with the mouse"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the link to the offer"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL copied"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am copying"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage repayment"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repayment"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show filter"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide filter"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price not stated"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The most expensive"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cheapest"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The latest"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking for hire"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent offers"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Areas"])},
    "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close detail"])},
    "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select locations"])},
    "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected locations"])},
    "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["region"])},
    "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["district"])},
    "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by locations"])},
    "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus property types"])},
    "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus the number of rooms"])},
    "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flatlet"])},
    "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One room"])},
    "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two rooms"])},
    "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three rooms"])},
    "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Four rooms"])},
    "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Five or more"])},
    "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus bonus filter"])},
    "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New construct"])}
  },
  "search": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am looking for"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For sale"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent offers"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State selection"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rooms"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum price"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does not matter"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum area"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area irrelevant"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in offers"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criteria of your interest"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent looking"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regions"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Districts"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers of this type are not available"])}
  },
  "details": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary content - Additional information"])}
  },
  "purchase": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an interest in buying real estate"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and should at least roughly meet these criteria"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of real estate"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rooms"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the village"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the district"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within the region"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum price"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate area"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["approx"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furnished property"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ideal option"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["it has to be"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of places"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideal orientation"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrace"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above-standard view"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air condition"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fireplace"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recreation area nearby"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land area"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["approx"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swimming pool"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bathrooms"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bedrooms"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at least"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balcony"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loggia"])}
  },
  "viewIcons-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["???"])},
  "viewIcons-title": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of offer"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of property"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usable area"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land area"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rooms"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the locality"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool available"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fireplace available"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upstairs"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of garages"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size of the terrace"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus device"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking options"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of balconies"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of loggias"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size of the yard"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus offers"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus offers"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer status"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bathrooms"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the recreation area"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required floors"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested of rooms"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptable price"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanted acreage"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air-conditioned"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View oriented to"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superior view"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading broker"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bedrooms"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of offices"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested office number"])},
    "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flatlet"])}
  },
  "viewIcons": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["???"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air condition"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land area"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientation"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the east"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the west"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the south"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the south"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North and east"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["East and south"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South and west"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West and north"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usable area"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrooms"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathrooms"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pool"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is included"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fireplace"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is available"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garages"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["count"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrace"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furnished"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balcony"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["count"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loggia"])},
    "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["count"])},
    "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size of the yard"])},
    "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New construction"])},
    "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconstruction"])},
    "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])},
    "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent request"])},
    "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental offer"])},
    "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury"])},
    "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive"])},
    "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
    "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathrooms"])},
    "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recreation"])},
    "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanted floor"])},
    "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested rooms"])},
    "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum price"])},
    "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searched area"])},
    "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broker"])},
    "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rooms"])},
    "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yard"])},
    "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrooms"])},
    "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact the broker"])},
    "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of offices"])},
    "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offices"])},
    "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chancellors wanted"])},
    "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flatlet"])}
  },
  "concat-makler": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact the broker"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager of this offer"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a message, at least 10 characters"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail to which you want a reply"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager of this offer"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To send"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending the message ended with an error"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The message was sent successfully"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will contact you without undue delay"])}
  },
  "addIcon": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furnished"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offered property is furnished"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfurnished"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offered property is furnished"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offered property is located on the floor of the number of floors"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usable area of the offered real estate"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area of the yard or garden to the property"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The size of the yard, terrace or garden of the property"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The area of the land offered by the property"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swimming pool"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part of the offered property is a swimming pool"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The view"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an above-standard view from the offered property"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garages"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offered property includes a garage"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air condition"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offered property includes air conditioning"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fireplace"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The property also has a fireplace or similar heating"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathrooms"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the offered property contains more bathrooms"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the east"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the west"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the south"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the south"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offered property is oriented to the world side"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possibility of parking within this offer"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rooms in the offered property"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrooms"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is more than one bedroom in the offered property"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance of the recreation area from the offered property"])},
    "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per offer"])},
    "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negotiated price"])},
    "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North and east"])},
    "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["East and south"])},
    "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South and west"])},
    "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West and north"])},
    "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of balconies"])},
    "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balcony"])},
    "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Loggias"])},
    "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loggia"])},
    "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property condition"])}
  },
  "estateType": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family house"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment build."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offices"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buss.premises"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administr.building"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer's project"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historical object"])}
  },
  "states": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak republic"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech Republic"])}
  },
  "setInLimit": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no information to set the price"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no information to set the acreage"])}
  },
  "gdpr": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We respect your privacy"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The operator of the application in question, as well as the companies working with this application, may also use, for example, cookies or other online identifiers, which also process your personal data, which may be necessary for the further functionality of the application, as well as its convenient setup."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed data relating to the user of the application can be used, for example, for the purpose of selecting the display of sensitive information."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed data relating to the user of the application can be used, for example, for the purpose of selecting the display of sensitive information. Said data can also be used for the purpose of creating anonymous statistics, or, for example, for displaying personalized content and personalized advertising according to the interests of the web application visitor."])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you confirm the button I understand and agree voluntarily, you can decide that you agree to the above conditions and actions. If you do not agree with what is stated in this section, you can leave the page."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I do not agree"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand and agree"])}
  },
  "maklers": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broker"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
  },
  "modalSendMessage": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write to the broker"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To send"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a message, at least 10 characters"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail to which you want a reply"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broker"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are sending a message to an offer"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been successfully sent to the relevant broker."])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The broker will contact you without undue delay."])},
    "9-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been successfully sent to the relevant broker."])},
    "10-818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contacted broker will contact you without unnecessary delay after receiving your message."])},
    "9-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been successfully sent to a developer offering programming in VUE JS 3."])},
    "10-abnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contacted developer will contact you without unnecessary delay after receiving your message."])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the window"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending your mail ended with an error."])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the problem persists, please contact the application administrator."])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a message to our agency, what other requirements you have for real estate, what you like about our application and what you don't."])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager of this offer"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a message"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to your e-mail address, which must be included, we recommend that you also include your other contacts in the message, such as a mobile number or WhatsApp."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have a question about my offer, message min. 10 characters please send it to me."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you can contact me by confirming this link"])}
  },
  "why-abnet": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why trade through RK"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will be pleased if you write to us"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are interested in the offer"])},
    "title": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation contracts"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer contracts"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert testimony"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit in the cadastre"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energies and their transcription"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advantageous mortgage"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friendly approach"])}
    },
    "content": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For new housing, the company RK can draw up a reservation contract for the selected property. Since you will not have to personally arrange the necessary details regarding the reservation of the property, you will have more time and financial resources available to you."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company RK will provide you with the drafting of not only purchase contracts, but also other types of contracts if the real estate transfer itself requires it. In this regard, the company can really provide you with absolute professionalism."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If an expert opinion is needed, you will also be provided with a competent and in all respects acceptable expert. As a bonus, the most favorable price on the market will be negotiated with the expert for the preparation of the expert opinion."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company RK can also represent you in the final phase of the transfer of real estate at the cadastre, i.e. during the cadastral deposit procedure. If further communication with the cadastral department is necessary when depositing the property, all necessary details will be thoroughly communicated with the relevant district office."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As part of the transfer of real estate, to which energy consumption is tied, such as electricity, water, etc., during your move, the company will ensure the transfer of energy, while you can fully devote your time to the move."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will arrange a mortgage exactly according to your needs, we have many years of experience with this. Thus, we will ensure the most favorable and ideal financing, through which you can calmly acquire your new property."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the event that we agree that you will use some of the above services offered by us, the RK company will provide you with a friendly approach and a suitable atmosphere during our communication, with consistent fulfillment of your requirements regarding a specific property and its transfer."])}
    }
  },
  "why-818": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do business with 818"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will be pleased if you write to us"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are interested in the offer"])},
    "title": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation contracts"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer contracts"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert testimony"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit in the cadastre"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energies and their transcription"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advantageous mortgage"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friendly approach"])}
    },
    "content": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For new housing, the company 818 can draw up a reservation contract for the selected property. Since you will not have to personally arrange the necessary details regarding the reservation of the property, you will have more time and financial resources available to you."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company 818 will provide you with the drafting of not only purchase contracts, but also other types of contracts if the real estate transfer itself requires it. In this regard, the company can really provide you with absolute professionalism."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If an expert opinion is needed, you will also be provided with a competent and in all respects acceptable expert. As a bonus, the most favorable price on the market will be negotiated with the expert for the preparation of the expert opinion."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company 818 can also represent you in the final phase of the transfer of real estate at the cadastre, i.e. during the cadastral deposit procedure. If further communication with the cadastral department is necessary when depositing the property, all necessary details will be thoroughly communicated with the relevant district office."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As part of the transfer of real estate, to which energy consumption is tied, such as electricity, water, etc., during your move, the company will ensure the transfer of energy, while you can fully devote your time to the move."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will arrange a mortgage exactly according to your needs, we have many years of experience with this. Thus, we will ensure the most favorable and ideal financing, through which you can calmly acquire your new property."])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the event that we agree that you will use some of the above services offered by us, the 818 company will provide you with a friendly approach and a suitable atmosphere during our communication, with consistent fulfillment of your requirements regarding a specific property and its transfer."])}
    }
  },
  "info-818": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we offer you ?"])}
  }
}